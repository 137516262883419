import React  from 'react'


const NotImplemented = (props) => {
    return (
        <div>
                          Not Implemented yet
               
        </div>
    )

}


export default NotImplemented