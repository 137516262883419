const projectdata =
    [

        {
            language: `en`,
            title: `Ferran Closa Cervera`,
            firstWord: `some`,
            spannedWord: `Projects`,
            lastWord: `I’ve enjoyed`,
            intro: `This is a sample of IT projects, most of them involving big complexity. I have provided my personal take from each project as, globally, they have been extremely useful experiences. Note that these projects tend to be large corporate projects, which means that there was a large team involved, although I was always making some difference in a positive way. Note the rating, which reflects how much I enjoyed the project. `,
            details: [
                {
                    title: `IT Modernisation programme`,
                    subtitle: `Modernisation`,
                    place: `Paris`,
                    year: `2014`,
                    code: `ITM`,
                    description: `This project consists on the review and implementation of all our systems to ensure that they are ready for the Digital Era. This means moving out of legacy systems and platforms into more distributed and API aware systems. This is where Microservices become relevant, as well as the Channels stream where we integrate solutions with customers and open secure channels`,
                    role: `IT CMB Architect`,
                    stars: 5,
                    order: 10
                },
                {
                    title: `HSBC France Fusion`,
                    subtitle: `Change of Legal Entity`,
                    place: `Paris`,
                    year: `2007`,
                    code: `FUS`,
                    role: `Data Migration Lead Architect`,
                    description: `This project involved a change on the HSBC legal entity from an Member of the Group HSBC to being HSBC France. There was both a massive data migration project, but also the impact on the customers, as all contracts and accounts had to be re-created under the new financial institution`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `Centre Commercial de France - HSBC`,
                    subtitle: `Migration`,
                    place: `Paris`,
                    year: `2006`,
                    code: `CCF`,
                    description: `This was a Data Migration project. This was also the largest of the kind within HSBC. 2.6 million customers were migrated from CCF to HSBC in France on a single weekend.`,
                    role: `Data Migration Lead Architect`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `Banque Hervé`,
                    subtitle: `Migration`,
                    level: `Excellent`,
                    description: `This was a Data Migration project, which was executed using robots, which via scripts, they simulated data capture and automated BAU exception handling. This approach was decided based on the small customer number (< 8K)`,
                    role: `Data Migration Lead Architect`,
                    stars: 1,
                    order: 30,
                    place: `Paris`,
                    year: `2005`,
                    code: `HER`,
                },
                {
                    title: `Union Bancaire de Paris`,
                    subtitle: `Migration`,
                    level: `Excellent`,
                    description: `This project thaught me a lesson. On the dangers of overconfidence. This project was a follow-up to the Banque de Picardie project, 
                            and there were incorrectly placed assumptions, that led to wrong decisions. The type of customer was very diffrent, as well as the product offering. 
                            At the end, everything was fine, but they were some difficult times following an implementation with plenty of errors.`,
                    role: `Data Migration Lead`,
                    stars: 2,
                    order: 40,
                    place: `Paris`,
                    year: `2004`,
                    code: `UBP`,
                },

                {
                    title: `Banque de Picardie`,
                    subtitle: `Migration`,
                    level: `Excellent`,
                    description: `Here, as it was the first one of many, I had to first adapt to French corporate culture, including learning the language, and then slowly but surely 'sell' my capabilities until they accepted me as one that could help them deliver what we had to deliver`,
                    role: `Data Migration Lead`,
                    stars: 3,
                    order: 50,
                    place: `Amiens`,
                    year: `2002`,
                    code: `PIC`,
                },
                {
                    title: `HSBC Turkey`,
                    subtitle: `Migration & Change of Accounting model`,
                    level: `Excellent`,
                    description: `Both Migrating data into a Core Banking System and implementing the change of the Turkish Lira, 
                        where last three zeros where dropped from the currency, During my stay, in Istambul, a terrorist attack against 
                        HSBC and the UK participation in the Irak war, stopped the project, and I was transferred back to London.`,
                    role: `Senior Developer `,
                    stars: 1,
                    order: 100,
                    place: `Istambul`,
                    year: `1999`,
                    code: `IST`,
                },
                {
                    title: `HSBC Malta`,
                    subtitle: `Migration`,
                    level: `Excellent`,
                    description: `Following the acquisition of the biggest bank in Malta, A poject was initiated to move to HSBC systems. This was my first large project abroad, and I, personally still feel a strong attachment and connection with that project and all that it represented for the team members that participated on it. In Malta my first daughter was born.`,
                    role: `Migration Lead`,
                    stars: 5,
                    order: 80,
                    place: `Valleta`,
                    year: `2001`,
                    code: `MAL`,
                }
                ,
                {
                    title: `Forbes UK`,
                    subtitle: `Creation of Distance Learning and Online Courses`,
                    level: `Excellent`,
                    description: `This project was something special: On my own initiative, I devised a system initially to automate test scorings, and then using Visual Basic and Office, automate the registration and enrolment process, followed by the creation of the learning material and the follow up of the assignments for the distance learning students. It was implemented by Forbes and it became the first Online and Distance learning center in the UK.`,
                    role: `Trainer and Developer `,
                    stars: 4,
                    order: 120,
                    place: `London`,
                    year: `1996-1997`,
                    code: `LON`,
                }
                ,
                {
                    title: `HSBC Greece`,
                    subtitle: `Migration, Upgrades`,

                    level: `Excellent`,
                    description: `This project involved the migration from a Barclays operation in Greece into the HSBC fold. So, some data migration, together with some issues derived from both Y2K and incoming EUR.`,
                    role: `Senior Developer `,
                    stars: 1,
                    order: 70,
                    place: `Athens`,
                    year: `2001`,
                    code: `ATH`,
                },
                {
                    title: `HSBC Converter `,
                    subtitle: `Migration Engine`,
                    place: `Malta, Athens, Paris`,
                    year: `2006`,
                    code: `ORQ`,
                    description: `This was a project that started as a pet project of mine and became the 'official' migration tool for HSBC iSeries Migrations, which were very numerous. 
                    The idea was to have a common set of tools that would help on all facets related to the Data Migration for ISeries systems, 
                    like Core Banking, Import & Export and others. The main components being a Menu Builder, a Scheduler builder, a performance wrapper, 
                    a reporting tool and a Transformation engine. The tools allowed for both a great deal of 'inheritance' and flexibility`,
                    role: `Lead Architect`,
                    stars: 5,
                    order: 20
                }
            ]
        },
        {
            language: `fr`,
            title: `Ferran Closa Cervera`,
            firstWord: `quelques `,
            spannedWord: `Projets`,
            lastWord: ` qui m’ont changé`,
            intro: `Ceci est un recueil de projets en TI, la plupart, d’une grande complexité. Tous ont abouti à des résultats positifs, et j’ai gardé ma vision personnelle de chaque projet ; puisqu’au niveau mondial, il s’agit d’expériences particulièrement utiles. Gardez à l’esprit que ce sont, en général, des grands projets d’entreprise. Notez bien le classement qui indique le niveau de plaisir que j’ai pris en assumant mon rôle.`,
            details: [
                {
                    title: `Programme de modernisation IT`,
                    subTitle: `Modernisation`,
                    place: `Paris`,
                    year: `2014`,
                    role: `Senior Developer `,
                    code: `ITM`,
                    description: `Ce projet consistait à réviser et à déployer tous nos systèmes pour nous assurer qu’ils seraient préparés pour l’ère numérique. Ceci impliquait le passage des systèmes et des plateformes hérités, vers des systèmes plus distribués et réactifs aux API. C’est ici que les microservices sont d’un intérêt particulier, tout comme le flux de canaux pour intégrer des solutions avec les clients, et établir des canaux sécurisés.`,
                    stars: 6,
                    order: 10
                },
                {
                    title: `Centre Commercial de France - HSBC`,
                    subtitle: `Migration`,
                    place: `Paris`,
                    year: `2006`,
                    code: `CCF`,
                    description: `Il s’agit d’un projet de migration de données. Et aussi du plus grand projet de ce genre au sein de HSBC. 2,6 Millions de clients ont été migrés de CCF à HSBC en France, en un seul week-end.`,
                    role: `Data Migration Lead Architect`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `HSBC France Fusion`,
                    subTitle: `Modification de l’entité légale`,
                    place: `Paris`,
                    year: `2007`,
                    role: `Senior Developer `,
                    code: `FUS`,
                    description: `Ce projet impliquait un changement de l’entité juridique HSBC d’un membre du groupe HSBC à HSBC France. Il y a eu à la fois un projet massif de migration des données, mais aussi l’impact sur les clients, car tous les contrats et comptes ont dû être recréés sous la nouvelle institution financière`,
                    stars: 2,
                    order: 20
                },
                {
                    title: `Banque Hervé`,
                    subTitle: `Migration des données`,

                    description: `Sur ce projet de migration de données, des robots ont été employés pour l’exécution. A l’aide de scripts, ils ont simulé la saisie des données et géré le traitement automatique des exceptions, la routine habituelle. Cette approche avait été décidée en fonction du petit nombre de clients (< 8K).   `,
                    stars: 5,
                    order: 30,
                    role: `Architecte en chef pour la migration des données`,
                    place: `Paris`,
                    year: `2005`,
                    code: `HER`,
                },
                {
                    title: `Union Bancaire de Paris`,
                    subTitle: `Migration des données`,

                    description: `Ce projet m’a appris une leçon, sur les dangers d’un excès de confiance. Ce projet suivait celui de la Banque de Picardie ; les hypothèses initiales mal formulées ont conduit à des décisions erronées. Le type de client était très différent, tout comme la gamme de produits offerts. A la fin, tout était bien à sa place, mais il y a eu des moments difficiles après une mise en pratique marquée par beaucoup d’erreurs. Cependant, cela m’a permis de montrer ma capacité de réaction face des situations de pression extrême.`,
                    stars: 1,
                    order: 40,
                    place: `Paris`,
                    role: `Responsable de la migration des données `,
                    year: `2004`,
                    code: `UBP`,
                },

                {
                    title: `Banque de Picardie`,
                    subTitle: `Migration des données`,
                    niveau: `Excellent`,
                    description: `C’était le premier de toute une série de projets de migration similaires. Le premier pas était de m’adapter à la culture entrepreneuriale française, y compris l’apprentissage de la langue. Ensuite, il s’agissait de « vendre » au mieux mes compétences, au fur et à mesure, pour être accepté en tant que personne capable de contribuer aux objectifs proposés.`,
                    stars: 1,
                    order: 50,
                    role: `Responsable de la migration des données `,
                    place: `Amiens`,
                    year: `2002`,
                    code: `PIC`,
                },
                {
                    title: `HSBC Turquie`,

                    subTitle: `Migration des données`,

                    description: `Un projet qui abritait la migration des données vers un système bancaire central, et aussi la mise en place de la modification de la lire turque, en supprimant les trois derniers zéros de la monnaie. Pendant mon séjour à Istanbul, une attaque terroriste contre HSBC et la participation du Royaume Uni dans la guerre de l’Irak ont stoppé le projet, et j’ai été rappelé à Londres.`,
                    stars: 1,
                    role: `Senior Developer `,
                    order: 100,
                    place: `Istambul`,
                    year: `1999`,
                    code: `IST`,
                },
                {
                    title: `HSBC Malte`,
                    subTitle: `Migration des données`,


                    description: `Suite à l’acquisition de la plus grande banque de Malte, un projet á été initié pour assurer le passage aux systèmes HSBC. C’était mon premier grand projet à l’étranger, et personnellement, j’ai gardé un lien et une connexion fortes avec ce projet et tout ce qu’il a signifié pour les membres de l’équipe qui y a participé. A Malte, ma première fille est née.`,
                    stars: 1,
                    role: `Développeur Senior `,
                    order: 80,
                    place: `Valleta`,
                    year: `2001`,
                    code: `MAL`,
                }
                ,
                {
                    title: `HSBC Grèce`,
                    subTitle: `Migration des données`,
                    description: `Ce projet impliquait la migration depuis une opération Barclays en Grèce vers le groupe HSBC. C’est-à-dire, un certain volume de migration de données, complété par quelques questions liées au passage à l’année 2000 et à l’introduction de l’euro.`,
                    stars: 1,
                    order: 70,
                    role: `Développeur Senior`,
                    place: `Athènes`,
                    year: `2001`,
                    code: `ATH`,
                }
            ]
        },
        {
            language: `sp`,
            title: `Ferran Closa Cervera`,
            firstWord: `algunos`,
            spannedWord: `Proyectos`,
            lastWord: `que me han cambiado`,
            intro: `Esta es una muestra de proyectos de TI, la mayoría de ellos con gran complejidad. 
                Todos ellos con resultados exitosos, he aportado mi visión personal de cada proyecto, 
                ya que, a nivel mundial, han sido experiencias sumamente útiles. Tenga en cuenta que estos proyectos tienden a ser grandes proyectos corporativos. Notese el rating, indicativo de lo que disfruté`,
            details: [
                {
                    title: `IT Modernisation programme`,
                    subtitle: `Modernisation`,
                    place: `Paris`,
                    year: `2014`,
                    code: `ITM`,
                    role: `IT CMB Architect`,
                    description: `Este proyecto consiste en la revisión e implementación de todos nuestros sistemas para asegurar que están listos para la Era Digital. Esto significa pasar de los sistemas y plataformas heredados a sistemas más distribuidos y conscientes de las API. Aquí es donde los microservicios cobran relevancia, así como el flujo de canales donde integramos soluciones con los clientes y abrimos canales seguros.`,
                    stars: 5,
                    order: 10
                },
                {
                    title: `HSBC France Fusion`,
                    subtitle: `Legal entity change`,
                    place: `Paris`,
                    year: `2007`,
                    code: `FUS`,
                    role: `Data Migration Lead Architect`,
                    description: `Este proyecto implicó un cambio en la entidad legal de HSBC de ser miembro del Grupo HSBC a ser HSBC Francia. Hubo un proyecto masivo de migración de datos, pero también el impacto en los clientes, ya que todos los contratos y cuentas tuvieron que ser recreados bajo la nueva institución financiera.`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `HSBC Converter `,
                    subtitle: `Motor Migratorio`,
                    place: `Malta, Athens, Paris`,
                    year: `2006`,
                    code: `ORQ`,
                    description: `Este fue un proyecto que comenzó como un proyecto favorito mío y se convirtió en la herramienta de migración "oficial" para HSBC iSeries migraciones, que eran muy numerosas. 
                    La idea era tener un conjunto común de herramientas que ayudaran en todas las facetas relacionadas con la migración de datos para sistemas ISeries, 
                    como Core Banking, Import & Export y otros. Los componentes principales son un constructor de menús, un generador de programadores, un contenedor de rendimiento, 
                    una herramienta de informes y un motor de transformación. Las herramientas permitieron tanto una gran cantidad de "herencia" como flexibilidad.`,
                    role: `Lead Architect`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `Centre Commercial de France - HSBC`,
                    subtitle: `Data Migration`,
                    place: `Paris`,
                    year: `2006`,
                    role: `Data Migration Lead Architect`,
                    code: `CCF`,
                    description: `Este fue un proyecto de migración de datos. Este fue también el más grande del tipo dentro de HSBC. 2,6 millones de clientes migraron de CCF a HSBC en Francia en un solo fin de semana.`,
                    stars: 5,
                    order: 20
                },
                {
                    title: `Banque Hervé`,
                    subtitle: `Data Migration`,
                    level: `Excellent`,
                    description: `Este fue un proyecto de migración de datos, que se ejecutó utilizando robots, que a través de scripts, simularon la captura de datos y el manejo automatizado de excepciones BAU. Este enfoque se decidió en función del pequeño número de clientes (< 8K)                `,
                    stars: 1,
                    role: `Data Migration Lead Architect`,
                    order: 30,
                    place: `Paris`,
                    year: `2005`,
                    code: `HER`,
                },
                {
                    title: `Union Bancaire de Paris`,
                    subtitle: `Data Migration`,
                    level: `Excellent`,
                    description: `Este proyecto me dio una lección. Sobre los peligros del exceso de confianza. Este proyecto fue una continuación del proyecto Banque de Picardie, y hubo suposiciones colocadas incorrectamente, que llevaron a decisiones equivocadas. El tipo de cliente era muy diferente, así como la oferta de productos. Al final, todo estuvo bien, pero fueron momentos difíciles después de una implementación con muchos errores. Al mismo tiempo, pude demostrar como reacciono ante situaciones de extrema presión`,
                    stars: 2,
                    role: `Data Migration Lead`,
                    order: 40,
                    place: `Paris`,
                    year: `2004`,
                    code: `UBP`,
                },

                {
                    title: `Banque de Picardie`,
                    subtitle: `Data Migration`,
                    level: `Excellent`,
                    description: `Aquí, como fue el primero de una serie de proyectos similares de migración, primero tuve que adaptarme a la cultura corporativa francesa, incluido el aprendizaje del idioma, y luego, "vender" mis capacidades de manera lenta pero segura hasta que me aceptaron como alguien que podría ayudarlos a entregar lo que teníamos que entregar.`,
                    role: `Data Migration Lead`,
                    stars: 3,
                    order: 50,
                    place: `Amiens`,
                    year: `2002`,
                    code: `PIC`,
                },
                {
                    title: `HSBC Turkey`,
                    subtitle: `Data Migration`,
                    level: `Excellent`,
                    description: `Tanto la migración de datos a un sistema bancario central como la implementación del cambio de la lira turca, 
                    donde los últimos tres ceros fueron eliminados de la moneda, Durante mi estancia, en Estambul, un ataque terrorista contra 
                    HSBC y la participación del Reino Unido en la guerra de Irak detuvieron el proyecto, y me transfirieron de regreso a Londres.`,
                    stars: 1,
                    role: `Senior Developer `,
                    order: 100,
                    place: `Istambul`,
                    year: `1999`,
                    code: `IST`,
                },
                {
                    title: `HSBC Malta`,
                    subtitle: `Data Migration`,
                    level: `Excellent`,
                    description: `Following the acquisition of the biggest bank in Malta, A poject was initiated to move to HSBC systems. This was my first large project abroad, and I, personally still feel a strong attachment and connection with that project and all that it represented for the team members that participated on it. In Malta my first daughter was born.`,
                    stars: 5,
                    role: `Migration Lead`,
                    order: 80,
                    place: `Valleta`,
                    year: `2001`,
                    code: `MAL`,
                }
                ,
                {
                    title: `HSBC Greece`,
                    subtitle: `Data Migration`,

                    level: `Excellent`,
                    description: `This project involved the migration from a Barclays operation in Greece into the HSBC fold. So, some data migration, together with some issues derived from both Y2K and incoming EUR.`,
                    stars: 1,
                    role: `Senior Developer `,
                    order: 70,
                    place: `Athens`,
                    year: `2001`,
                    code: `ATH`,
                },
                {
                    title: `Forbes UK`,
                    subtitle: `Creation of Distance Learning and Online Courses`,
                    level: `Excellent`,
                    description: `This project was something special: On my own initiative, I devised a system initially to automate test scorings, and then using Visual Basic and Office, automate the registration and enrolment process, followed by the creation of the learning material and the follow up of the assignments for the distance learning students. It was implemented by Forbes and it became the first Online and Distance learning center in the UK.`,
                    role: `Trainer and Developer `,
                    stars: 4,
                    order: 120,
                    place: `London`,
                    year: `1996-1997`,
                    code: `LON`,
                }
                ,
            ]
        },


    ]

export default projectdata