

import 'assets/css/main.css'

import Footer from 'components/Footer';
import MainRouter from 'components/MainRouter';



function App() {
  return (
    <>
   
      <MainRouter />
 
      <Footer></Footer>

    </>


  );
}

export default App;
