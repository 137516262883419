import React from 'react';

const Footer =() => {

    return (
        <div id='main-footer'>
            Copyright &copy; {new Date().getFullYear()}
        </div>
    )
}

export default Footer; 