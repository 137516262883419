const languages =
    [
        {
            language: "sp",
            text: "Castellano",
            en: "Inglés",
            // ca: "Catalàn",
            fr: "Francés"

        },
        {
            language: "en",
            text:"English",
            sp: "Spanish",
            // ca: "Catalan",
             fr: "French"

        },
        // {
        //     language: "ca",
        //     sp: "Castellà",
        //     en: "Anglés",
        //     text: "Català",
        //     fr: "Francés"
        // },
        {
            language: "fr",
            // ca: "Catalan",
            sp: "Espagnol",
            en: "Anglais",
            text: "Français",
        }
    ]


export default languages